const TextEnum = {
    WHAT_TEXT: "SAIA es una Sociedad Argentina de Inteligencia Artificial que tiene como objetivo principal promover el avance, desarrollo y aplicaciones de la inteligencia artificial en Argentina. Trabajamos en estrecha colaboración con expertos, académicos y profesionales para impulsar la innovación y difundir el conocimiento en este campo.",
    HOW_CAN_SAIA_HELP_TEXT: "SAIA puede ayudarte brindando recursos, conocimientos y oportunidades en el ámbito de la inteligencia artificial. Organizamos eventos, conferencias y talleres donde puedes aprender sobre las últimas tendencias y prácticas en IA. Además, ofrecemos una plataforma de networking donde puedes conectarte con profesionales y expertos en el campo.",
    WHAT_SAIA_IS_DIFFERENT_TEXT: "SAIA es una fundación con una visión amplia y un compromiso ético en el desarrollo y aplicación de la inteligencia artificial. Nuestra fortaleza es la comunidad creada por una red de colaboradores y aliados que nos permite acceder a una amplia gama de recursos y experiencias para contribuir con responsabilidad social en este campo.",
    INNOVATION_TEXT: "SAIA se compromete a fomentar y promover la innovación en el campo de la inteligencia artificial. Buscamos desarrollar nuevas soluciones, tecnologías y enfoques que impulsen el progreso y mejoren la vida de las personas en diferentes aspectos.",
    COLABORATION_TEXT: "Valoramos la colaboración y la sinergia entre profesionales, académicos y entidades de diversos sectores. Fomentamos la creación de redes de trabajo conjuntas, la participación en proyectos colaborativos y la difusión del conocimiento para construir una comunidad de inteligencia artificial sólida y cohesionada.",
    TRANSPARENCY_TEXT: "SAIA se compromete a promover la transparencia en el desarrollo y aplicación de la inteligencia artificial. Buscamos fomentar la apertura y la divulgación de información sobre los algoritmos, modelos y datos utilizados en los sistemas de IA. Promovemos la comprensión pública de la inteligencia artificial y sus implicaciones, y abogamos por la rendición de cuentas y la toma de decisiones claras y justificables.",
    ETHIC_TEXT: "La ética es un pilar fundamental para SAIA. Promovemos el uso responsable y ético de la inteligencia artificial, teniendo en cuenta las implicaciones sociales, legales y morales. Buscamos garantizar la transparencia, la privacidad, la equidad y la seguridad en todas las aplicaciones de la inteligencia artificial."
};

export default TextEnum

