'use client'
import React, { useState } from 'react'
import { ArrowRight2, ArrowLeft2 } from 'iconsax-react'
import QuoteIcon from '../../assets/QuoteIcon.svg'

const Carousel = ({ data, start = 1 }) => {
  const [posTestimonial, setPosTestimonial] = useState(start)
  const startOfCarousel = posTestimonial === 0 ? 'bg-gray-550' : 'bg-white'
  const endOfCarousel = posTestimonial === data.length - 1 ? 'bg-gray-550' : 'bg-white'
  return (
    <div>
      {data[posTestimonial] && (
        <div className='flex flex-col justify-center items-center gap-y-8  '>
          <div className='flex flex-col justify-center items-center'>
            <div className='w-[95%] flex flex-col justify-center items-center -space-y-5 '>
              <div className='w-[102px] h-[102px] z-10 rounded-full bg-gradient-to-r from-[#201b3b] via-[#5d1749] to-[#ac1354] flex items-center justify-center'>
                <img
                  src={data[posTestimonial].image}
                  alt={data[posTestimonial].role}
                  className='w-[90%] h-[90%] rounded-full object-cover'
                />
              </div>
              <div className='w-full  bg-[#1e1d3d] rounded-lg flex flex-row pt-[51px] pb-8 px-4 gap-x-3 space-y-0'>
                <div className='h-7 w-[10%]'>
                <img src={QuoteIcon} alt='Quote Icon' />
                </div>
                <div className='flex flex-col gap-y-4 w-[90%]'>
                  <div className='text-white text-base font-normal'>
                    {data[posTestimonial].text}
                  </div>
                  <div>
                    <p className='text-white text-lg font-bold'>
                      {data[posTestimonial].name}
                    </p>
                    <p className='text-white text-sm font-light'>
                      {data[posTestimonial].role}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-row justify-center items-center gap-2'>
            <div
              className={`h-12 w-12 rounded-full flex items-center justify-center ${startOfCarousel} `}
              onClick={() => setPosTestimonial(Math.max(posTestimonial - 1, 0))}
            >
              {posTestimonial === 0 ? (
                <ArrowLeft2 color='#DDE3ED' />
              ) : (
                <ArrowLeft2 color='#0B096F' />
              )}
            </div>
            <div
              className={`h-12 w-12 rounded-full flex items-center justify-center ${endOfCarousel}`}
              onClick={() =>
                setPosTestimonial(Math.min(posTestimonial + 1, data.length - 1))
              }
            >
              {posTestimonial === data.length - 1 ? (
                <ArrowRight2 color='#DDE3ED' />
              ) : (
                <ArrowRight2 color='#0B096F' />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Carousel
