import tongImage from '../../assets/testimonials/henry.jpeg';
import pedroImage from '../../assets/testimonials/pedro.jpg';
import marcelaImage from '../../assets/testimonials/gaitan.png';

export const TestimonialsES = [
  {
    number: '1',
    image: marcelaImage,
    text: 'En Saia encontré un apoyo vital tras mi consulta en el Chat de Información y Consulta IA. Una voluntaria, me ayudó con gran eficacia, mejorando significativamente los resultados. ',
    name: 'Marcela Gaitán',
    role: 'Socia de SAIA'
  },
  {
    number: '2',
    image: tongImage,
    text: 'SAIA ha sido un recurso invaluable para mí en el campo de la inteligencia artificial. Me permitió explorar y conectar con expertos y proyectos, y aprender sobre iniciativas como INNOVADUCATE que llevan a la IA a las escuelas.',
    name: 'Henry Tong',
    role: 'Arquitecto de software con más de 20 años de experiencia.'
  },
  {
    number: '3',
    image: pedroImage,
    text: 'Mi experiencia en SAIA fue transformadora. Conecté con SAIA, aprendiendo como la IA puede innovar en educación. La red de profesionales de SAIA fue clave. Agradecido por cada aprendizaje.',
    name: 'Pedro Figueroa',
    role: 'Global Teacher Award 2021 | Orador TEDx | Profesor Universitario'
  }
]