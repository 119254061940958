export const SaiaFullLogoBlack = ({ width = 100, height = 35 }) => (
  <svg width={width}
    height={height} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="60 570 1300 280" style={{enableBackground: "new 0 0 595.3 841.9"}} xmlSpace="preserve">
    <g>
      <g>
        <g>
          <path d="M325.7,409.1c0.3,0.4,0.6,0.8,1,1s0.9,0.3,1.4,0.3c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.4-0.4,0.5-0.6
				c0.1-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.2-0.5-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-1-0.3
				c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.8c-0.2-0.3-0.2-0.7-0.2-1.2c0-0.5,0.1-0.9,0.3-1.2
				c0.2-0.4,0.4-0.7,0.7-0.9c0.3-0.2,0.7-0.4,1.1-0.5c0.4-0.1,0.8-0.2,1.3-0.2c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,1
				l-0.8,0.6c-0.2-0.3-0.5-0.6-0.9-0.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.3,0-0.6,0-0.9,0.1c-0.3,0.1-0.5,0.2-0.7,0.4
				c-0.2,0.2-0.4,0.4-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.5,0.1,0.9,0.4,1.2c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.3,1.3,0.4
				c0.5,0.1,0.9,0.3,1.3,0.5c0.4,0.2,0.7,0.5,1,0.9c0.3,0.4,0.4,0.9,0.4,1.5c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.4-0.4,0.7-0.7,0.9
				c-0.3,0.2-0.6,0.4-1,0.6c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.2-1.1-0.6-1.5-1.2L325.7,409.1z"/>
          <path d="M335.7,405.9c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1c0.7-0.3,1.4-0.4,2.2-0.4
				s1.5,0.1,2.2,0.4c0.7,0.3,1.2,0.6,1.7,1.1c0.5,0.5,0.8,1.1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.8-0.1,1.5-0.4,2.2
				c-0.3,0.7-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.7,1.1c-0.7,0.3-1.4,0.4-2.2,0.4s-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7C335.9,407.4,335.7,406.7,335.7,405.9z M336.7,405.9c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4
				c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3s1.3-0.1,1.8-0.3c0.5-0.2,1-0.6,1.4-1c0.4-0.4,0.7-0.9,0.9-1.4
				c0.2-0.6,0.3-1.1,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.6-0.5-1-0.9-1.4c-0.4-0.4-0.8-0.7-1.4-1c-0.5-0.2-1.1-0.3-1.8-0.3
				s-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4C336.8,404.7,336.7,405.3,336.7,405.9z"/>
          <path d="M360,409.4c-0.5,0.7-1.1,1.2-1.7,1.4c-0.7,0.3-1.4,0.4-2.1,0.4c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1
				c0.7-0.3,1.4-0.4,2.2-0.4c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.7,1.6,1.2l-0.8,0.7c-0.3-0.5-0.7-0.8-1.2-1.1
				c-0.5-0.2-1-0.3-1.5-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.6-0.3,1.2-0.3,1.8
				c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3c0.3,0,0.5,0,0.8-0.1
				c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.5-0.4,0.6-0.7L360,409.4z"/>
          <path d="M364.6,400.7h1V411h-1V400.7z" />
          <path d="M371.3,400.7h6.3v0.9h-5.3v3.7h5v0.9h-5v4h5.6v0.9h-6.5V400.7z" />
          <path d="M382.8,400.7h3.2c0.9,0,1.7,0.1,2.3,0.3c0.6,0.2,1.1,0.5,1.6,0.8c0.4,0.3,0.7,0.7,1,1.1c0.2,0.4,0.4,0.8,0.5,1.2
				c0.1,0.4,0.2,0.7,0.2,1c0,0.3,0,0.5,0,0.6c0,0.1,0,0.3,0,0.6c0,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.3,0.8-0.5,1.2
				c-0.2,0.4-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.6-1.6,0.8c-0.6,0.2-1.4,0.3-2.3,0.3h-3.2V400.7z M383.7,410.2h2c0.7,0,1.3-0.1,1.9-0.3
				c0.6-0.2,1.1-0.4,1.5-0.8s0.8-0.8,1-1.3c0.3-0.5,0.4-1.2,0.4-1.9c0-0.7-0.1-1.4-0.4-1.9c-0.3-0.5-0.6-1-1-1.3
				c-0.4-0.3-1-0.6-1.5-0.8c-0.6-0.2-1.2-0.3-1.9-0.3h-2V410.2z"/>
          <path d="M399.7,400.7h1l4.3,10.3H404l-1.1-2.7h-5.5l-1.2,2.7h-1L399.7,400.7z M400.2,401.8l-2.4,5.7h4.7L400.2,401.8z" />
          <path d="M409.3,400.7h3.2c0.9,0,1.6,0.1,2.3,0.3c0.6,0.2,1.1,0.5,1.6,0.8c0.4,0.3,0.7,0.7,1,1.1c0.2,0.4,0.4,0.8,0.5,1.2
				c0.1,0.4,0.2,0.7,0.2,1c0,0.3,0,0.5,0,0.6c0,0.1,0,0.3,0,0.6c0,0.3-0.1,0.6-0.2,1c-0.1,0.4-0.3,0.8-0.5,1.2
				c-0.2,0.4-0.6,0.8-1,1.1c-0.4,0.3-0.9,0.6-1.6,0.8c-0.6,0.2-1.4,0.3-2.3,0.3h-3.2V400.7z M410.3,410.2h2c0.7,0,1.3-0.1,1.9-0.3
				c0.6-0.2,1.1-0.4,1.5-0.8c0.4-0.3,0.8-0.8,1-1.3c0.3-0.5,0.4-1.2,0.4-1.9c0-0.7-0.1-1.4-0.4-1.9c-0.3-0.5-0.6-1-1-1.3
				c-0.4-0.3-1-0.6-1.5-0.8c-0.6-0.2-1.2-0.3-1.9-0.3h-2V410.2z"/>
          <path d="M433,400.7h1l4.3,10.3h-1.1l-1.1-2.7h-5.5l-1.2,2.7h-1L433,400.7z M433.5,401.8l-2.4,5.7h4.7L433.5,401.8z" />
          <path d="M442.8,400.7h2.7c0.1,0,0.3,0,0.6,0c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.8,0.5s0.4,0.5,0.6,0.8
				c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.5,0.3-0.9,0.4c-0.3,0.1-0.6,0.2-0.9,0.2
				l3,5h-1.1l-2.9-4.9h-1.8v4.9h-1V400.7z M443.7,405.3h2c0.5,0,0.9-0.1,1.2-0.2c0.3-0.1,0.6-0.3,0.7-0.5c0.2-0.2,0.3-0.4,0.4-0.6
				c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.7-0.5c-0.3-0.1-0.7-0.2-1.2-0.2h-2
				V405.3z"/>
          <path d="M462.8,410.4c-0.6,0.3-1.2,0.6-1.9,0.7c-0.6,0.1-1.3,0.2-2,0.2c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1
				c0.7-0.3,1.4-0.4,2.2-0.4c1.5,0,2.8,0.5,3.7,1.4l-0.7,0.7c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.2-0.5-0.3-0.7-0.4
				c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4
				c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3
				c0.6,0,1.1,0,1.6-0.1c0.5-0.1,0.9-0.2,1.3-0.5v-3.5h-2.5v-0.9h3.4V410.4z"/>
          <path d="M468.6,400.7h6.3v0.9h-5.3v3.7h5v0.9h-5v4h5.6v0.9h-6.5V400.7z" />
          <path d="M480.1,400.7h1.2l6.3,9h0v-9h1V411h-1.2l-6.3-9h0v9h-1V400.7z" />
          <path d="M496.6,401.6h-3.5v-0.9h8v0.9h-3.5v9.4h-1V401.6z" />
          <path d="M505.6,400.7h1V411h-1V400.7z" />
          <path d="M512.3,400.7h1.2l6.3,9h0v-9h1V411h-1.2l-6.3-9h0v9h-1V400.7z" />
          <path d="M529.7,400.7h1L535,411H534l-1.1-2.7h-5.5l-1.2,2.7h-1L529.7,400.7z M530.2,401.8l-2.4,5.7h4.7L530.2,401.8z" />
        </g>
        <g>
          <path d="M325,417.8h2.6c0.7,0,1.3,0.1,1.8,0.3c0.5,0.2,0.9,0.4,1.3,0.7c0.3,0.3,0.6,0.6,0.8,0.9c0.2,0.3,0.3,0.7,0.4,1
				c0.1,0.3,0.1,0.6,0.2,0.8c0,0.2,0,0.4,0,0.5s0,0.3,0,0.5c0,0.2-0.1,0.5-0.2,0.8c-0.1,0.3-0.2,0.6-0.4,1c-0.2,0.3-0.5,0.6-0.8,0.9
				c-0.3,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1.1,0.3-1.8,0.3H325V417.8z M325.8,425.5h1.6c0.6,0,1.1-0.1,1.6-0.2c0.5-0.1,0.9-0.3,1.3-0.6
				c0.4-0.3,0.6-0.6,0.8-1.1c0.2-0.4,0.3-1,0.3-1.6c0-0.6-0.1-1.1-0.3-1.6c-0.2-0.4-0.5-0.8-0.8-1.1c-0.4-0.3-0.8-0.5-1.3-0.6
				c-0.5-0.1-1-0.2-1.6-0.2h-1.6V425.5z"/>
          <path d="M336.3,417.8h5.1v0.7h-4.3v3h4.1v0.7h-4.1v3.3h4.5v0.7h-5.3V417.8z" />
          <path d="M351.4,415.9h1v10.3h-1V415.9z" />
          <path d="M358.1,415.9h1.2l6.3,9h0v-9h1v10.3h-1.2l-6.3-9h0v9h-1V415.9z" />
          <path d="M374.6,416.7h-3.5v-0.9h8v0.9h-3.5v9.4h-1V416.7z" />
          <path d="M383.6,415.9h6.3v0.9h-5.3v3.7h5v0.9h-5v4h5.6v0.9h-6.5V415.9z" />
          <path d="M395.2,415.9h1v9.4h4.8v0.9h-5.8V415.9z" />
          <path d="M405.4,415.9h1v10.3h-1V415.9z" />
          <path d="M420.6,425.5c-0.6,0.3-1.2,0.6-1.9,0.7c-0.6,0.1-1.3,0.2-2,0.2c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1
				c0.7-0.3,1.4-0.4,2.2-0.4c1.5,0,2.8,0.5,3.7,1.4l-0.7,0.7c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.2-0.5-0.3-0.7-0.4
				c-0.3-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4
				c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3
				c0.6,0,1.1,0,1.6-0.1c0.5-0.1,0.9-0.2,1.3-0.5v-3.5h-2.5v-0.9h3.4V425.5z"/>
          <path d="M426.3,415.9h6.3v0.9h-5.3v3.7h5v0.9h-5v4h5.6v0.9h-6.5V415.9z" />
          <path d="M437.8,415.9h1.2l6.3,9h0v-9h1v10.3h-1.2l-6.3-9h0v9h-1V415.9z" />
          <path d="M460.6,424.5c-0.5,0.7-1.1,1.2-1.7,1.4c-0.7,0.3-1.4,0.4-2.1,0.4c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1
				c0.7-0.3,1.4-0.4,2.2-0.4c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.7,1.6,1.2l-0.8,0.7c-0.3-0.5-0.7-0.8-1.2-1.1
				c-0.5-0.2-1-0.3-1.5-0.3c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.6-0.3,1.2-0.3,1.8
				c0,0.6,0.1,1.2,0.3,1.8c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3c0.3,0,0.5,0,0.8-0.1
				c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.5-0.4,0.6-0.7L460.6,424.5z"/>
          <path d="M465.2,415.9h1v10.3h-1V415.9z" />
          <path d="M475.1,415.9h1l4.3,10.3h-1.1l-1.1-2.7h-5.5l-1.2,2.7h-1L475.1,415.9z M475.6,416.9l-2.4,5.7h4.7L475.6,416.9z" />
        </g>
        <g>
          <path d="M328.6,431h1l4.3,10.3h-1.1l-1.1-2.7h-5.5l-1.2,2.7h-1L328.6,431z M329,432l-2.4,5.7h4.7L329,432z" />
          <path d="M338.3,431h2.7c0.1,0,0.3,0,0.6,0c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.8,0.5
				c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.5,0.3-0.9,0.4
				c-0.3,0.1-0.6,0.2-0.9,0.2l3,5h-1.1l-2.9-4.9h-1.8v4.9h-1V431z M339.3,435.5h2c0.5,0,0.9-0.1,1.2-0.2c0.3-0.1,0.6-0.3,0.7-0.5
				c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.7-0.5
				c-0.3-0.1-0.7-0.2-1.2-0.2h-2V435.5z"/>
          <path d="M352.2,431.8h-3.5V431h8v0.9h-3.5v9.4h-1V431.8z" />
          <path d="M361.2,431h1v10.3h-1V431z" />
          <path d="M367.9,431h6.2v0.9h-5.2v3.7h4.8v0.9h-4.8v4.9h-1V431z" />
          <path d="M378.9,431h1v10.3h-1V431z" />
          <path d="M394.1,439.7c-0.5,0.7-1.1,1.2-1.7,1.4c-0.7,0.3-1.4,0.4-2.1,0.4c-0.8,0-1.5-0.1-2.2-0.4c-0.7-0.3-1.2-0.6-1.7-1.1
				c-0.5-0.5-0.8-1.1-1.1-1.7c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.7-1.1
				c0.7-0.3,1.4-0.4,2.2-0.4c0.7,0,1.4,0.1,2,0.4c0.6,0.2,1.1,0.7,1.6,1.2L393,433c-0.3-0.5-0.7-0.8-1.2-1.1c-0.5-0.2-1-0.3-1.5-0.3
				c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.6,0.1,1.2,0.3,1.8
				c0.2,0.6,0.5,1,0.9,1.4c0.4,0.4,0.8,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3c0.3,0,0.5,0,0.8-0.1c0.3-0.1,0.6-0.2,0.8-0.3
				c0.3-0.1,0.5-0.3,0.8-0.5c0.2-0.2,0.5-0.4,0.6-0.7L394.1,439.7z"/>
          <path d="M398.8,431h1v10.3h-1V431z" />
          <path d="M408.6,431h1l4.3,10.3h-1.1l-1.1-2.7h-5.5l-1.2,2.7h-1L408.6,431z M409.1,432l-2.4,5.7h4.7L409.1,432z" />
          <path d="M418.4,431h1v9.4h4.8v0.9h-5.8V431z" />
        </g>
      </g>
      <g>
        <path d="M61,436.3l4.9-2.8c5.1,11.2,13.5,15,26,15c14.2,0,20.7-4.9,20.7-13.1c0-9.9-9.2-10.8-23.7-11.1c-9-0.2-26.8-0.3-26.8-17.2
			c0-11.9,8.8-19.3,28.6-19.3c11,0,20.3,2.3,25.6,12.5l-5.1,2.9c-2.8-4.1-6.6-10.1-22.1-10.1c-12.4,0-21.8,3.9-21.8,13.8
			c0,13.1,16.3,12.5,25.8,12.8c15.8,0.4,24.8,2.7,24.8,15.2c0,12.9-9.3,19.2-26,19.2C74,454.2,65.1,447.2,61,436.3z"/>
        <path d="M167.5,436.6h-37.9l-7.4,16.7h-5.8l28.8-64.5h6.3l29.6,64.5h-6L167.5,436.6z M165.2,431.4l-16.7-37.2l-16.5,37.2H165.2z"
        />
        <path d="M185.4,453.2h10.3c1.5,0,2.8-1.2,2.8-2.8v-61.7h-10.3c-1.5,0-2.8,1.2-2.8,2.8V453.2z" />
        <path d="M242.9,388.8h-15.6l-24.5,64.4h9.3c2.3,0,4.4-1.4,5.2-3.6l19.8-51.9l12.4,31.9h-25l6.4,7.6c1.1,1.3,2.6,2,4.3,2h18.1
			l4.1,10.5c0.8,2.1,2.9,3.5,5.2,3.5h9.5l-23.9-60.9C247.2,390.2,245.2,388.8,242.9,388.8z"/>
      </g>
    </g>
  </svg>);
