import React, { useEffect } from "react"
import 'aos/dist/aos.css'
import AOS from 'aos'


const Values = () => {
    useEffect(() => {
        AOS.init()
      }, [])
    return (
        <div data-aos='fade-left' data-aos-offset='100' style={{background: 'radial-gradient(circle at right, #c6508c 0%, #000000 20%)'}} className="text-4xl font-bold text-white flex items-center justify-center h-24">Nuestros valores</div>
    )
}

export default Values