import React from 'react'
import Carousel from './carousel'

import { TestimonialsES } from './testimonials'
import CarouselDesktop from './carouselDesktop'


const Testimonials = () => {

  const data = TestimonialsES

  return (
    <div className='flex justify-center items-center bg-gray-50'>
      <div
        data-aos='zoom-in'
        data-aos-duration='1000'
        className='flex flex-col px-4 gap-6 md:px-[100px] relative my-10 max-w-8xl'
      >
        <div className='hidden md:flex absolute left-[12%] bottom-[30%] w-[200px] h-[200px] bg-blue-circle rounded-full blur-[200px] z-0' />
        <div className='hidden md:flex absolute right-[12%] top-[30%] w-[200px] h-[200px] bg-pink-circle rounded-full blur-[200px] z-0' />
        <div className='gap-2 flex flex-col justify-center items-center'>
          <p className='text-[#ac1354] text-center'>Testimonios</p>
          <p className='text-[#201b3b] text-[25px] lg:text-3xl font-bold text-center lg:w-[60%] lg"leading-[55px]'>
          ¿Qué opina la gente sobre SAIA?
          </p>
        </div>
        <div className='flex flex-row items-center justify-center gap-x-[180px]'>
          <div className='hidden lg:flex '>
            <CarouselDesktop data={data} />
          </div>
          <div className='flex lg:hidden'>
            <Carousel data={data}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
