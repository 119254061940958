import { SaiaFullLogoBlack } from "../assets/saia-full-black";


const Footer = () => {

  return (
    <footer className="bg-white overflow-hidden" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div className="h-7">
            <SaiaFullLogoBlack width={1000} height={500} />
            </div>
            <div className="space-y-1">
              <p className="mt-20 text-sm leading-6 text-black">Zabaleta 27 1ro B </p>
              <p className="text-sm leading-6 text-black">Ciudad Autónoma de Buenos Aires </p>
              <p className="text-sm leading-6 text-black">Argentina </p>
              <p className="text-sm leading-6 text-black">CUIT 33-71736089-9 </p>
              <p className="text-sm leading-6 text-black">administracion@saia.ar</p>
            </div>
            <div className="flex space-x-6">
            <a href="https://open.spotify.com/episode/09wsWrhTlnzHBhgOQ8XN7j?si=Ac8B3vzaQUyx15KIOshWMA&nd=1&dlsi=766e2d3a1e804c93" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Spotify</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M12 0C5.373 0 0 5.373 0 12c0 6.627 5.373 12 12 12s12-5.373 12-12C24 5.373 18.627 0 12 0zm5.09 17.29c-.19.31-.59.41-.9.22-2.5-1.52-5.64-1.87-9.36-1.02-.34.08-.68-.13-.76-.47-.08-.34.13-.68.47-.76 4.03-.91 7.45-.52 10.18 1.15.31.19.41.59.22.88zm1.27-2.56c-.24.38-.75.5-1.13.26-2.87-1.76-7.25-2.27-10.64-1.24-.43.12-.88-.12-1-.55-.12-.43.12-.88.55-1 3.8-1.13 8.57-.57 11.83 1.42.38.23.5.74.26 1.11zm.15-2.61c-.29.46-.91.61-1.37.32-3.27-2.01-8.25-2.6-12.11-1.41-.52.15-1.06-.14-1.21-.66-.15-.52.14-1.06.66-1.21 4.33-1.31 9.77-.68 13.45 1.55.46.28.61.9.32 1.37z"/>
                </svg>
              </a>
              <a href="https://www.facebook.com/profile.php?id=100090704183099" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="https://www.instagram.com/saia_ar/" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Instagram</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="https://twitter.com/SAIA_AR" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Twitter</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="https://github.com/saiacode" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">GitHub</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"  target="_blank" rel="noopener noreferrer">
                  <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="https://www.linkedin.com/company/sociedad-argentina-de-inteligencia-artificial" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M19.994 3H4.006C3.45 3 3 3.45 3 4.006v15.988C3 20.55 3.45 21 4.006 21h15.988C20.55 21 21 20.55 21 19.994V4.006C21 3.45 20.55 3 19.994 3zM8.339 18H5.666V8.997h2.673V18zm-1.336-10.338c-.859 0-1.556-.697-1.556-1.556 0-.86.697-1.556 1.556-1.556.86 0 1.556.697 1.556 1.556 0 .859-.696 1.556-1.556 1.556zM18 18h-2.667v-4.667c0-1.112-.02-2.54-1.546-2.54-1.547 0-1.787 1.212-1.787 2.46V18H9.333V8.997h2.56v1.23h.037c.356-.675 1.227-1.387 2.527-1.387 2.703 0 3.203 1.778 3.203 4.09V18z" clipRule="evenodd" />
                </svg>
              </a>
              <a href="https://www.youtube.com/channel/UCU0O2NUvhNI8F6gzMduFQOg" className="text-black hover:text-gray-500"  target="_blank" rel="noopener noreferrer">
                <span className="sr-only">YouTube</span>
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-[6.5rem]">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Nosotros</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                  <a href="/equipo" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Equipo</a>
                  </li>

                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Comunidad</h3>
                <ul className="mt-6 space-y-4">
                <li>
                    <a href="https://tally.so/r/mBdlK5" target="_blank" rel="noopener noreferrer" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Ser parte</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900">Comunicaciones</h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <a href="/prensa" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Prensa</a>
                  </li>
                  <li>
                    <a href="/entrevistas" className="text-sm leading-6 text-gray-600 hover:text-gray-900">Entrevistas</a>
                  </li>
                </ul>
              </div>
            </div>
          </div> 
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">&copy; 2023 Fundación Argentina de Inteligencia Artificial. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>


  );
}

export default Footer;

