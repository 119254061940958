'use client'
import React, { useState } from 'react'
import { ArrowRight2, ArrowLeft2 } from 'iconsax-react'
import QuoteIcon from '../../assets/QuoteIcon.svg'
import './carousel.css'

const MAX_VISIBILITY = 2

const CarouselDesktop = ({ data, start = 1 }) => {
  const [active, setActive] = useState(start)

  const startOfCarousel = active === 0 ? 'bg-gray-550' : 'bg-white'
  const endOfCarousel = active === data.length - 1 ? 'bg-gray-550' : 'bg-white'

  return (
    <div className='relative flex flex-col justify-center items-center gap-y-8 lg:w-[700px]'>
      <div className=' flex gap-10 carousel transition-all h-[400px]  '>
        {data.map((item, i) => {
          return (
            <div
              key={i}
              onClick={() => setActive(i)}
              className='w-[95%] flex flex-col justify-center items-center -space-y-10 card-container  '
              style={
                {
                  '--active': i === active ? 1 : 0,
                  '--offset': (active - i) / 3,
                  '--direction': Math.sign(active - i),
                  '--abs-offset': Math.abs(active - i) / 3,
                  pointerEvents: active === i ? 'auto' : 'none',
                  opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
                  display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'flex'
                }
              }
            >
              <div className='overflow-hidden w-[102px] h-[102px] z-10 rounded-full bg-gradient-to-r from-[#201b3b] via-[#5d1749] to-[#ac1354] flex items-center justify-center'>
                <img
                  src={item.image}
                  alt={item.role}
                  className='w-[90%] h-[90%] rounded-full object-cover'
                />
              </div>
              <div className='h-80 bg-[#1e1d3d] rounded-lg flex flex-row pt-[51px] pb-8 px-4 gap-x-3 lg:w-[450px] '>
                <div className='h-7 w-[10%]'>
                <img src={QuoteIcon} alt='Quote Icon' />
                </div>
                <div className='flex flex-col justify-between w-[90%] text-white'>
                  <div className='text-base font-normal'>{item.text}</div>
                  <div className='flex gap-5 mt-6 items-center'>
                    <div className='h-14 w-14'>
                      <img src={item.logoURL} alt={item.companyLogo} />
                    </div>
                    <div>
                      <p className='text-lg font-bold'>{item.name}</p>
                      <p className='text-sm font-light'>{item.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='flex flex-row justify-center items-center gap-2 self-end'>
        <div
          className={`h-12 w-12 rounded-full flex items-center justify-center hover:cursor-pointer ${startOfCarousel} `}
          onClick={() => setActive(Math.max(active - 1, 0))}
        >
          {active === 0 ? <ArrowLeft2 color='#DDE3ED' /> : <ArrowLeft2 color='#0B096F' />}
        </div>
        <div
          className={`h-12 w-12 rounded-full flex items-center justify-center hover:cursor-pointer ${endOfCarousel}`}
          onClick={() => setActive(Math.min(active + 1, data.length - 1))}
        >
          {active === data.length - 1 ? (
            <ArrowRight2 color='#DDE3ED' />
          ) : (
            <ArrowRight2 color='#0B096F' />
          )}
        </div>
      </div>
    </div>
  )
}

export default CarouselDesktop
