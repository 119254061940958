const links = [
    {
      id: 1,
      text: "Prensa",
      link: "Prensa",
    },
    {
      id: 2,
      text: "Equipo",
      link: "Equipo",
    },
    {
      id: 3,
      text: "Entrevistas",
      link: "Entrevistas",
    }
    
  ];


  export default links