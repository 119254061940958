import React, { useState } from 'react';
import interviews from '../../assets/interviews/interviews.json';
import { MdPlayCircleOutline } from "react-icons/md";


const Interviews = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className=''>
      <div style={{ background: 'radial-gradient(circle, rgba(172, 127, 244, 0.5) 0%, #000000 10%)' }} className='h-full w-full mt-2'>
      <div className="ellipsis z-20 opacity-20" />
        <h2 className='text-white justify-center flex text-xl font-bold lg:text-3xl'>Ciclo de entrevistas 2024</h2>
        <div className='border-2 border-white rounded-xl m-6 h-72 md:h-96 lg:h-[50vh] xl:h-[65vh] xl:m-24 '>
        {selectedVideo ? (
         <iframe
            className='w-full h-full rounded-xl'
            src={selectedVideo.video}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            autoPlay
        ></iframe>
          ) : (
            <img
            src='https://saia-images.s3.sa-east-1.amazonaws.com/saia.jpg'
            alt='SAIA'
            className='w-full h-full rounded-xl object-cover'
          />
          )}
        </div>
        <div className='grid grid-cols md:grid-cols-2 px-2 xl:mt-12'>
          {interviews.entrevistas.map((interview) => (
            <div key={interview.id} className="p-4 flex items-center justify-center flex-col relative cursor-pointer" onClick={() => { setSelectedVideo(interview); scrollToTop(); }}>
            <img
             src={interview.imagen}
             alt={interview.titulo}
             className="rounded-2xl border-2 border-white w-full  lg:h-[40vh] lg:w-[70vh]"
           />
           <MdPlayCircleOutline className='text-white text-8xl absolute bottom-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
           <div className='flex flex-col items-center justify-center '>
             <h5 className='text-white text-sm lg:text-lg mt-6'>Episodio {interview.id}</h5>
             <h3 className="text-white text-lg lg:text-2xl font-bold lg:mt-2">{interview.nombre}</h3>
             <p className="text-white text-sm lg:text-lg mt-1 mb-4 lg:mt-2 lg:mb-12  md:max-w-[25rem] lg:max-w-[40rem]">{interview.descripcion}</p>
           </div>
         </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Interviews;  