import React from 'react'
import {FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaSpotify, FaYoutube} from "react-icons/fa"
import { BsTwitterX } from 'react-icons/bs';



const SocialLinks = () => {

    const links = [
        {
            id:1,
            child: (
                <>
                Spotify <FaSpotify size={30}/>
                </>
            ),
            href: "https://open.spotify.com/episode/09wsWrhTlnzHBhgOQ8XN7j?si=Ac8B3vzaQUyx15KIOshWMA&nd=1&dlsi=766e2d3a1e804c93",
            style: "rounded-tr-md"
        },
        {
            id:2,
            child: (
                <>
                Facebook <FaFacebook size={30}/>
                </>
            ),
            href: "https://www.facebook.com/people/saia_ar/100090704183099/"
        },
        {
            id:3,
            child: (
                <>
                Instagram <FaInstagram size={30}/>
                </>
            ),
            href: "https://www.instagram.com/saia_ar/",
        },
        {
            id:4,
            child: (
                <>
                Twitter / X <BsTwitterX size={30}/>
                </>
            ),
            href: "https://x.com/SAIA_AR"
        },
        {
            id:5,
            child: (
                <>
                Github <FaGithub size={30}/>
                </>
            ),
            href: "https://github.com/saiacode",
        },
        {
            id:6,
            child: (
                <>
                Youtube <FaYoutube size={30}/>
                </>
            ),
            href: "https://www.youtube.com/channel/UCU0O2NUvhNI8F6gzMduFQOg",
        },
        {
            id:7,
            child: (
                <>
                LinkedIn <FaLinkedin size={30}/>
                </>
            ),
            href: "https://www.linkedin.com/company/sociedad-argentina-de-inteligencia-artificial",
            style: "rounded-br-md"
        }
    ]


  return (
    <div className='hidden xl:flex flex-col top-[35%] left-0 fixed'>   
      <ul>

        {links.map(({id, child, href, style,download})=>(
               <li key={id} className={`flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gradient-to-r from-[#201b3b] via-[#5d1749] to-[#ac1354] ${style}`}>
               <a 
                href={href} 
                className='flex justify-between items-center w-full text-white' 
                download={download}
                target='_blank'
                rel='noreferrer'
                >
                {child}
               </a>
           </li>

        ))}
   
      </ul>
    </div>
  )
}

export default SocialLinks